<template>
  <div class="all-height d-flex justify-center">
    <div class="all-width">
      <div class="d-flex align-center">
        <v-btn class="shadow-off mb-2" text @click="$nova.gotoLink({path: '/earnout/project'})"><v-icon left>mdi-arrow-left</v-icon> Back to Project list</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="shadow-off mb-2" text @click="refreshData()"><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
      </div>

      <v-card class="shadow2-a" loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text>
          <div class="mb-2">
            <v-chip v-if="project.stage === 3" small label color="error">Closed</v-chip>
            <v-chip v-else small label color="success">Open</v-chip>
          </div>
          <div class="py-2 d-flex align-center">
            <div>
              <div class="caption mb-n1">Target Company</div> 
              <div class="subtitle-1 mt-0 font-weight-bold">{{project.targetcompany || ''}}</div> 
            </div>
            <div class="px-4">
              <v-icon>mdi-arrow-right-bold</v-icon>
            </div>
            <div>
              <div class="caption mb-n1">Buyer Company</div> 
              <div class="subtitle-1 mt-0 font-weight-bold">{{project.buyercompany || ''}}</div> 
            </div>
          </div>
          <div>
            <span class="mr-2"><v-icon class="mr-1">mdi-calendar-range</v-icon>Aquisition Date:</span>
            <span class="mr-1">{{$nova.formatDate(project.aquisitiondate)}}</span>
          </div>
          <div class="d-flex align-center mt-2">
            <v-chip label small outlined color="" class="pl-0">
              <v-chip color="" label small class="mr-2 caption">Network:</v-chip>
              {{project.network}}
            </v-chip>
            <v-chip label small outlined color="" class="pl-0 ml-2">
              <v-chip color="" label small class="mr-2 caption">Region:</v-chip>
              {{project.region}}
            </v-chip>
            <v-chip label small outlined color="" class="pl-0 ml-2">
              <v-chip color="" label small class="mr-2 caption">Country:</v-chip>
              {{project.country}}
            </v-chip>
            <v-chip label small outlined color="" class="pl-0 ml-2">
              <v-chip color="" label small class="mr-2 caption">Currency:</v-chip>
              {{project.currency}}
            </v-chip>
            <v-spacer></v-spacer>
          </div>
        </v-card-text>
        <v-card-text>
          <div class="d-flex align-center">
            <v-btn v-if="project.stage !== 3 && isreviewer" class="shadow-off primary" small @click="adddialog=true;addeditdata={};addediteerror={}"><v-icon left>mdi-plus</v-icon>Add New Earnout</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="project.stage === 3 && isreviewer" color="success" @click="open()" small class="shadow-off"><v-icon class="mr-1">mdi-close</v-icon>Re-open Earnout</v-btn>
            <v-btn v-else-if="isreviewer" color="error" @click="closedialog=true" small class="shadow-off"><v-icon class="mr-1">mdi-close</v-icon>Close Earnout</v-btn>
          </div>
          <div v-if="(earnouts || []).length === 0" class="text-h5 text-center pa-8 grey--text">
            No earnouts added yet
          </div>
          <div v-else class="mt-4">
            <v-simple-table>
              <template v-slot:default>
                  <thead>
                  <tr class="">
                      <th class="text-left"  id="stages" rowspan="2">Holding of shares at various stages</th>
                      <th class="text-center" id="stages" colspan="2">Earn out period</th>
                      <th class="text-center" id="stages" colspan="2">Promoters</th>
                      <th class="text-left" id="stages" rowspan="2">No of shares to be transferred from Promoters</th>
                      <th class="text-center" id="stages" colspan="2">{{project.buyercompany || ''}}</th>
                      <th class="text-center" id="stages" colspan="2">Total Shares</th>
                      <th class="text-left" id="stages" rowspan="2">Whether Earnout completed</th>
                      <th class="text-center" id="stages" rowspan="2" v-if="project.stage !== 3 && isreviewer">Action</th>
                  </tr>
                  <tr class="">
                      <th class="text-left" id="Start">Start</th>
                      <th class="text-left" id="Start">End</th>
                      <th class="text-left" id="Start">No. of shares</th>
                      <th class="text-left" id="Start">%age</th>
                      <th class="text-left" id="Start">No. of shares</th>
                      <th class="text-left" id="Start">%age</th>
                      <th class="text-left" id="Start">No. of shares</th>
                      <th class="text-left" id="Start">%age</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(v, k) in earnouts" :key="k" class="cursor-pointer" v-ripple @click.stop="$nova.gotoLink({path: '/earnout/project/assignment/'+v._id})">
                      <td>{{v.stagename}}</td>
                      <td>{{$nova.formatDate(v.start)}}</td>
                      <td>{{$nova.formatDate(v.end)}}</td>
                      <td>{{$nova.formatNumber(v.promotorshare)}}</td>
                      <td>
                        {{((Number(v.promotorshare)/(Number(v.promotorshare)+Number(v.companyshare)))*100).toFixed(2)}}%<br/>
                        <div :style="`height:3px;width:${((Number(v.promotorshare)/(Number(v.promotorshare)+Number(v.companyshare)))*100).toFixed(2)}%`" class="warning"></div>
                      </td>
                      <td>{{$nova.formatNumber(Number(v.companyshare) - ((earnouts[k-1] || {}).companyshare || 0))}}</td>
                      <td>{{$nova.formatNumber(v.companyshare)}}</td>
                      <td>
                        {{((Number(v.companyshare)/(Number(v.promotorshare)+Number(v.companyshare)))*100).toFixed(2)}}%<br/>
                        <div :style="`height:3px;width:${((Number(v.companyshare)/(Number(v.promotorshare)+Number(v.companyshare)))*100).toFixed(2)}%`" class="success"></div>
                      </td>
                      <td>{{$nova.formatNumber(Number(v.promotorshare) + Number(v.companyshare))}}</td>
                      <td>100%</td>
                      <td>
                        <div class="">
                          <v-chip small label v-if="v.stage===2" color="success">Completed</v-chip>
                          <v-chip small label v-else-if="v.stage===0 && project.stage !== 3" color="info">Pending</v-chip>
                          <v-chip small label v-else-if="v.stage===1 && project.stage !== 3" color="warning">Ongoing</v-chip>
                          <v-chip small label v-else-if="project.stage === 3" color="error">Suspended</v-chip>
                        </div>
                      </td>
                      <td v-if="project.stage !== 3 && isreviewer">
                        <v-menu offset-y transition="slide-y-transition" close-on-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="view-on-hover-item-d mx-2 px-0" icon v-bind="attrs" v-on="on"><v-icon>mdi-menu</v-icon></v-btn>
                          </template>
                          <v-list dense class="pa-0 border-left-default">
                            <v-list-item v-if="v.stage!==2" @click="addeditdata={...v};editdialog=true;addediterror={}">
                              <v-list-item-title><v-icon left>mdi-pencil</v-icon>Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="v.stage===2" @click="openAssignment(k, v._id)">
                              <v-list-item-title><v-icon left>mdi-autorenew</v-icon>Re-Open</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-else @click="closeAssignment(k, v._id)">
                              <v-list-item-title><v-icon left>mdi-cancel</v-icon>Close</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>

      </v-card>

      <v-dialog v-model="adddialog" max-width="600" persistent>
        <v-card :loading="addloading" :disabled="addloading" loader-height="2" class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Add Earnout</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="adddialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="mt-4 pt-4">
            <v-row>
              <v-col cols="12" class="mb-0 py-1">
                <lb-string v-model="addeditdata.stagename" label="Earnout Stage Name" :error="addediteerror.stagename"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <lb-date v-model="addeditdata.start" label="Period Start" :error="addediteerror.start"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <lb-date v-model="addeditdata.end" label="Period End" :error="addediteerror.end"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <lb-number v-model="addeditdata.promotorshare" label="Promotor's No. of Shares" :error="addediteerror.promotorshare"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <lb-number v-model="addeditdata.companyshare" label="Company's No. of Shares" :error="addediteerror.companyshare"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="d-flex">
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="add()">Add</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="editdialog" max-width="600" persistent>
        <v-card :loading="editloading" :disabled="editloading" loader-height="2" class="">
          <v-card-text class="primary white--text d-flex align-center py-2 px-4">
            <span class="subtitle-1 white--text">Edit Earnout</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="editdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-text>
          <v-card-text class="mt-4 pt-4">
            <v-row>
              <v-col cols="12" class="mb-0 py-1">
                <lb-string v-model="addeditdata.stagename" label="Earnout Stage Name" :error="addediteerror.stagename"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <lb-date v-model="addeditdata.start" label="Period Start" :error="addediteerror.start"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <lb-date v-model="addeditdata.end" label="Period End" :error="addediteerror.end"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <lb-number v-model="addeditdata.promotorshare" label="Promotor's No. of Shares" :error="addediteerror.promotorshare"/>
              </v-col>
              <v-col cols="6" class="my-0 py-1">
                <lb-number v-model="addeditdata.companyshare" label="Company's No. of Shares" :error="addediteerror.companyshare"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="d-flex">
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="edit()">Update</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="closedialog" max-width="600" persistent>
        <v-card :loading="closeloading" :disabled="closeloading" loader-height="2" class="">
          <v-card-text class="pt-6">
            <div class="title mb-4">Confirm!</div>
            <div>
              This will close the project and the you will not be able to perform any changes to the project.<br/>
              <span class="font-weight-bold">Do you want to proceed?</span>
            </div>
          </v-card-text>
          <v-card-text class="text-right">
            <v-btn small @click="closedialog = false" class="">Cancel</v-btn>
            <v-btn small class="ml-2" color="error" @click="close()">Proceed</v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>

<script>

export default {
  name: 'earnout_project_view',
  data: function(){
    return {
      id: "",
      project: {},
      earnouts: [],
      isreviewer: false,
      loading: false,
      addloading: false,
      adddialog: false,
      editloading: false,
      editdialog: false,
      addeditdata: {},
      addediteerror: {},
      closedialog: false,
      closeloading: false
    }
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  mounted() {
  },
  methods: {
    refreshData() {
      this.isreviewer = (((this.$store.state.user || {}).permission || {}).earnout_assignment || []).indexOf('review') > -1
      this.id = this.$route.params.id || "";
      this.getData();
    },
    getData() {
      this.loading = true;
      return this.axios.post("/v2/earnout/project/get/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.project = dt.data.data[0] || {};
        }else throw new "error reading form data";
        return this.axios.post("/v2/earnout/project/getdetails/"+this.id);
      }).then(dt => {
        this.earnouts = [];
        if(dt.data.status === "success"){
          this.earnouts = dt.data.data;
        }else throw new "Error reading earnout list";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    add() {
      this.addloading = true;
      this.addeditdata.project = this.id;
      this.axios.post("/v2/earnout/assignment/add", {data: this.addeditdata}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Earnout Added");
          this.adddialog = false;
          this.refreshData();
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addediteerror = dt.data.data[0].index0;
          }else throw new dt.data.message;
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addloading = false;
      })
    },
    edit() {
      this.editloading = true;
      this.axios.post("/v2/earnout/assignment/edit/"+this.addeditdata._id, {data: this.addeditdata}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Earnout Updated");
          this.editdialog = false;
          this.refreshData();
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addediteerror = dt.data.data[0].index0;
          }else throw new dt.data.message;
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.editloading = false;
      })
    },
    close() {
      this.closeloading = true;
      this.axios.post("/v2/earnout/project/close/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project Closed");
          this.project.stage = 3;
          this.closedialog = false;
          this.tab = 1;
        }else throw new "Error closing project";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.closeloading = false;
      })
    },
    open() {
      this.loading = true;
      this.axios.post("/v2/earnout/project/open/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Project Re-opened");
          this.project.stage = 0;
        }else throw new "Error re-opening project";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    closeAssignment(k, i) {
      this.loading = true;
      this.axios.post("/v2/earnout/assignment/close/"+i).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Earnout Closed");
          this.earnouts[k].stage = 2;
          this.earnouts.push({});
          this.earnouts.pop();
        }else throw new "Error closing earnout";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    openAssignment(k, i) {
      this.loading = true;
      this.axios.post("/v2/earnout/assignment/open/"+i).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Earnout Re-opened");
          this.earnouts[k].stage = 1;
          this.earnouts.push({});
          this.earnouts.pop();
        }else throw new "Error re-opening earnout";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  computed: {
  },
  watch: {
    "$store.state.client": function(){
      this.refreshData();
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-tabs .v-tab {
  height: 40px !important;
}
</style>